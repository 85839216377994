import * as Sentry from '@sentry/remix'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

Sentry.init({
  dsn: 'https://21962f49cec3b6388e7c8e8ca78b3c9a@o4505444271783936.ingest.us.sentry.io/4506650981105664',
  replaysOnErrorSampleRate: 0.25,
  tracesSampleRate: 0.01,
  integrations: [
    Sentry.browserTracingIntegration({ useEffect, useLocation, useMatches }),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      // Defines how to handle errors that contain third party stack frames.
      // Possible values are:
      // - 'drop-error-if-contains-third-party-frames'
      // - 'drop-error-if-exclusively-contains-third-party-frames'
      // - 'apply-tag-if-contains-third-party-frames'
      // - 'apply-tag-if-exclusively-contains-third-party-frames'
      behaviour: 'drop-error-if-exclusively-contains-third-party-frames',

      // Specify the application keys that you specified in the Sentry bundler plugin
      filterKeys: ['omni-website-js'],
    }),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
